/*
 * 업무구분: 활동
 * 화 면 명: MSPAP312P
 * 화면설명: 고객선택 팝업 (전화활동 고객명 검색에서 사용하는 고객선택 팝업)
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2025.03.11
 * 작 성 자: 최종환
 */
<template>
  <ur-page-container class="msp" title="고객선택" :show-title="true" type="subpage">
    <ur-box-container direction="column" alignV="start">
      
      <!-- 검색결과 있을 경우 -->
      <ur-box-container v-if="!isEmptyView" direction="column" alignV="start" componentid="ur_box_container_000" class="msp-cm-wrap">
        <ur-box-container direction="column" alignV="start" componentid="ur_box_container_001" class="ns-radio-list2 customer-list-ty">
          <mo-list :list-data="items">
            <template #list-item="{ item }">
              <mo-list-item>
                <!-- 라디오 버튼 영역 -->
                <mo-radio v-model="radio" :value="item.chnlCustId" @input="fn_CheckSelectedItem(item)"/>

                <!-- 체크박스 영역 확인 필요.. -->
                <div class="list-item__contents" @click="fn_CheckSelectedItem(item)">
                  <div class="list-item__contents__title">
                    <!-- 고객명 -->
                    <span class="name">{{item.custNm}}</span>

                    <!-- 고객구분 -->
                    <mo-badge class="badge-sample-badge" text="가망" shape="status" v-if="item.chnlCustScCd === '01'">가망</mo-badge>
                    <mo-badge class="badge-sample-badge" text="임시" shape="status" v-else-if="item.chnlCustScCd === '02'">임시</mo-badge>
                    <mo-badge class="badge-sample-badge" text="본인" shape="status" v-else-if="item.chnlCustScCd === '03'">본인</mo-badge>
                    <mo-badge class="badge-sample-badge" text="타인" shape="status" v-else-if="item.chnlCustScCd === '04'">타인</mo-badge>
                    <mo-badge class="badge-sample-badge" text="관심" shape="status" v-else-if="item.chnlCustScCd === '05'">관심</mo-badge>
                    <mo-badge class="badge-sample-badge" text="" shape="status" v-else></mo-badge>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 생년월일 -->
                    <span>{{$commonUtil.dateDashFormat(item.birthNo)}}</span><em>|</em>
                    <!-- 성별 -->
                    <span>{{item.sxdsCdNm}}</span><em>|</em>
                    <!-- 나이 -->
                    <span>{{item.age}}세</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span>필수컨설팅동의<b v-if="item.infoPrcusMcnstYn === 'Y'" class="crTy-blue2 pl10">{{item.infoPrcusMcnstYn}}</b><b v-else class="crTy-orange2 pl10">{{item.infoPrcusMcnstYn}}</b>
                    </span>
                    <em>|</em>
                    <span>마케팅동의<b v-if="item.infoPrcusMktYn === 'Y'" class="crTy-blue2 pl10">{{item.infoPrcusMktYn}}</b><b v-else class="crTy-orange2 pl10">{{item.infoPrcusMktYn}}</b>
                    </span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
          <!-- 맨 아래 여백 (데이터 짤림 방지) -->
          <div class="ns-height50"></div>
        </ur-box-container>
      </ur-box-container>

      <!-- 검색 결과 없을 경우 -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button v-if="!useMktCheck" componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_NewCustomer">닫기</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="selectedItem.chnlCustId === undefined" @click="fn_ConfirmContent">확인</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP312P",
    screenId: "MSPAP312P",
    components: {
      MspBottomSelect
    },
    props: {
      pCustNm: String, // 채널고객명
      pCnsltNo: String, //컨설턴트사번
      callbackFn: {
        type: Function,
        default: () => false
      },
      useMktCheck: {
        type: Boolean,
        default: false,
      },
      isUnseCust: {
        type: Boolean,
        default: false,
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // 고객명이 파라미터로 넘어온 경우
      if (this.fn_IsValidAccess) {
        this.custNm = this.pCustNm
        this.cnsltNo = this.pCnsltNo

        console.log('this.custNm', this.custNm)
        console.log('this.cnsltNo', this.cnsltNo)

        // 서비스 호출
        this.fn_SearchCustomerInfo()
      }
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPAP312P') //화면 접속기록 남기기
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        radio: '', // 라디오 변수
        title: '고객선택 팝업', // 화면명
        custNm: '', // 고객명
        items: [],
        selectedItem: {}, // 선택한 값 Obj
        isEmptyView: false
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      fn_IsValidAccess () {
        if (this.pCustNm !== null && this.pCustNm !== '' 
            && this.pCnsltNo !== null && this.pCnsltNo !== '') {
          return true
        } else {
          return false
        }
      },
    },
    /***********************************************************************************
     * watch 정의 영역
     ***********************************************************************************/
    watch: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_SearchCustomerInfo
      * 설명       : 고객명으로 고객 정보활용 동의 정보 조회 서비스 호출
      ******************************************************************************/
      fn_SearchCustomerInfo () {
        let lv_Vm = this
        lv_Vm.btnConfirmDisabled = true

        let pParams = {
          cnsltNo: lv_Vm.cnsltNo, // 컨설턴트사번
          custNm: lv_Vm.custNm.trim() // 고객명
        }

        console.log('fn_SearchCustomerInfo pParams', pParams)

        //let trnstId = 'txTSSCM10S4'
        let trnstId = 'txTSSCM10S9'

        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            // 필수 컨설팅 리스트
            if (response.body !== null) {
              if (response.body.cMInfoPrcusCnsntListVO !== null) {
                lv_Vm.items = response.body.cMInfoPrcusCnsntListVO

                if (lv_Vm.items.length > 0) {
                  lv_Vm.isEmptyView = false
                } else {
                  lv_Vm.isEmptyView = true
                }
              } else {
                lv_Vm.items = []
                lv_Vm.isEmptyView = true
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_Click
      * 설명       : row 선택 시 호출. 선택된 row의 radio value를 변경
      ******************************************************************************/
      fn_CheckSelectedItem (item) {
        this.selectedItem = {} // 초기화
        this.selectedItem = item

        this.radio = item.chnlCustId
      },

      /******************************************************************************
      * Function명 : fn_ConfirmContent
      * 설명       : 확인버튼 선택 시 호출 - 선택한 항목의 값을 Main 창으로 돌려보냄.
      ******************************************************************************/
      fn_ConfirmContent () {

        if (this.selectedItem !== null) { // 리스트가 선택될 경우 '확인' 버튼이 활성화 됨. 따라서 선택하지 않을 경우를 체크할 필요 없음
          if (this.useMktCheck && this.selectedItem.infoPrcusMktYn === 'N') {
            if (this.isUnseCust) {
              this.getStore('confirm').dispatch('ADD', '마케팅동의가 되어 있는 고객만 선택가능합니다. \n\n마케팅동의가 되지 않은 고객은  직접 기본정보를 입력하시기 바랍니다.')
            } else {
              this.getStore('confirm').dispatch('ADD', '마케팅동의가 되어 있는 고객만 선택가능합니다.')
            }
            return
          }

          this.selectedItem.isExistCustYN = 'Y'
          let lv_Param = {
            custCardVO: this.selectedItem,
            isExistCustYN: 'Y',
            custNm: this.selectedItem.custNm,
            chnlCustId: this.selectedItem.chnlCustId,
            chnlCustScCd: this.selectedItem.chnlCustScCd,
            chnlCstgrId: this.selectedItem.chnlCstgrId,
            rrnFrnoNo: this.selectedItem.rrnFrnoNo,
            mnCustRltnCd: this.selectedItem.mnCustRltnCd,
            unAprvCustYn: this.selectedItem.unAprvCustYn,
            age: this.selectedItem.age,
            celno: this.selectedItem.celno,
            knb: this.selectedItem.knb,
          }
          this.$emit('confirmPopup', lv_Param)
        }
      },
      /******************************************************************************
      * Function명 : fn_NewCustomer
      * 설명       : 닫기 버튼 선택 시 호출 - 선택한 항목의 값을 Main 창으로 돌려보냄.
      ******************************************************************************/
      fn_NewCustomer () {
        let lv_Param = {
          isExistCustYN: 'N'
        }
        this.$emit('confirmPopup', lv_Param)
      }

    }//methods end
  };
</script>
<style scoped>
</style>