/*
 * 업무구분: 활동
 * 화 면 명: MSPAP311P
 * 화면설명: 전화활동 검색
 * 작 성 일: 2025.02.24
 * 작 성 자: 최종환
 */
<template>
  <ur-page-container title="검색" :show-title="true" type="subpage" class="msp myCallHistory">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- scroll 영역 -->
          <template #scroll>

            <!-- 관리자 기본검색필터 -->
            <ur-box-container direction="column" alignV="start" class="msp-ap-wrap" v-if="fn_IsManager">
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-add-area mb30">
                <ur-box-container alignV="start" componentid="" direction="column" class="">
                  <msp-bottom-select class="ns-dropdown-sheet" :items="lv_HofOrgData" v-model="lv_HofOrg.key" @input="fn_GetDofData" :disabled="lv_OrgAuth.isEntpwRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="사업부선택" closeBtn scrolling placeholder="사업부선택"/>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
                  <msp-bottom-select class="ns-dropdown-sheet" :items="lv_DofOrgData" v-model="lv_DofOrg.key" @input="fn_GetFofData" :disabled="lv_OrgAuth.isHofRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="지역단선택" closeBtn scrolling placeholder="지역단선택"/>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
                  <msp-bottom-select class="ns-dropdown-sheet" ref="refFofOrgData" :items="lv_FofOrgData" v-model="lv_FofOrg.key" @input="fn_GetFofCnsltData" :disabled="lv_OrgAuth.isDofRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="지점선택" closeBtn scrolling placeholder="지점선택"/>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="mt20" v-if="$props.pPage !== 'MSPAP610M'">
                  <msp-bottom-select class="ns-dropdown-sheet" ref="refFofCnsltData" :items="lv_FofCnsltData" v-model="lv_FofCnslt.key" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="컨설턴트 선택" closeBtn scrolling placeholder="컨설턴트 선택"/>
                </ur-box-container>

              </ur-box-container>
            </ur-box-container>
            <!-- //관리자 기본검색필터 -->

            <!-- 탭(고객명/메모) 구조 검색 -->
            <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1">
              <!-- 탭(고객명/메모) -->
              <div class="ns-segment myCallHistoryWrap">
                <mo-segment-wrapper solid primary v-model="searchType">
                  <mo-segment-button value="고객명 검색">고객명</mo-segment-button>
                  <mo-segment-button value="메모 검색">메모</mo-segment-button>
                </mo-segment-wrapper>
                <mo-text-field 
                  v-model="searchText"
                  ref="searchTextInput" 
                  searchable 
                  :placeholder="searchType" 
                  underline 
                  class="sch-input no-sch myCallHistorySearch"
                  @keyup="() => { searchText = searchText.toUpperCase() }"
                  @keyup.enter="fn_OnClickSearch"
                  @click-icon="fn_OnClickSearch"
                  />
              </div>
              <!-- //탭(고객명/메모) -->

              <div class="myCallHistoryTotalWrap">'<span>{{searchResultText}}</span>' 검색 결과 총<span class="myCallHistoryTotal">{{searchCount}}</span>건</div>

              <!-- 정렬선택 (최신순보기/수신만보기/발신만보기) -->
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexTy3">
                    <div class="fexTy1"></div>
                    <msp-bottom-select 
                      ref="bottomSelect" 
                      :items="sortCdList" 
                      v-model="sortCd.value" 
                      @:itemValue="'value'" 
                      :itemText="'text'" 
                      underline class="ns-dropdown-sheet maxW130 type-1 fs14rem" 
                      placeholder="정렬선택" 
                      bottom-title="정렬선택" 
                      closeBtn/>
                  </div>
                </div>
              </mo-list-item>
              <!-- //정렬선택 (최신순보기/수신만보기/발신만보기) -->

              <!-- 검색결과 노출영역 -->
              <div v-for="(obj, idx) in searchTelHistoryList" :key="idx" class="myCallHistoryWrap">
                <div class="myCallHistorySearchDate">{{obj.formattedDate}}</div>
                <div v-for="(item, index) in obj.data" :key="index" class="itemBox" :class="{'gray':item.SR==='발신','blue':item.SR==='수신'}">
                  <div class="myCallHistoryNameWrap">
                    <span class="myCallHistoryName" @click="fn_OpenCustNmUiCard(item)">{{item.name}}</span>
                    <div class="myCallHistoryBtnWrap">
                      <mo-button class="blackWH myCallHistoryBtnModify" @click="fn_SaveMemo(item)">{{item.memo ? '수정' : '입력' }}</mo-button>
                      <mo-icon icon="msp-close-bold" class="myCallHistoryBtnClose" @click="fn_BottomConfirm('삭제하시겠습니까?', item.CheckSn, item.chnlCustId )">msp-close-bold</mo-icon>
                    </div>
                  </div>
                  <div class="mt5">
                    <span class="myCallHistorySR" :class="{'myCallHistorySRBlue':item.SR==='수신'}">{{item.SR}}</span>
                    <span class="myCallHistoryTime">{{item.CntcCntnt}}</span>
                  </div>
                  <div class="myCallHistoryMemo" v-if="item.memo" v-html="item.memo"></div>
                </div>
              </div>
              <!-- //검색결과 노출영역 -->

              <!-- 검색결과 미존재 시 -->
              <ur-box-container v-if="fn_IsEmptySearchResult" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data mt50">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info small-space h200px"> 
                      <span class="ns-ftcr-gray full">
                        <b class="fwn crTy-blue2 mt10">{{searchResultText}}</b>에 대한 검색결과가 없습니다.
                      </span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
              <!-- 검색결과 미존재 시 //-->

            </ur-box-container>
            <!-- //탭(고객명/메모) 구조 검색 -->

            <!-- 하단 버튼 영역(초기화/조회) -->
            <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column"  class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
              </div>
            </ur-box-container>
            <!-- //하단 버튼 영역(초기화/조회) -->
          </template>

        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPCM139P from '@/ui/cm/MSPCM139P' // 전화메모 추가, 수정
import MSPAP312P from '@/ui/ap/MSPAP312P' // 고객명 검색 팝업
import apConstants from '@/config/constants/apConstants'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPAP311P',
  // 현재 화면 ID
  screenId: 'MSPAP311P',
  // 컴포넌트 선언
  components: {
    MspBottomSelect,
    MSPCM139P,
    MSPAP312P,
  },
  // 화면명
  props: {
    pPage: String, // 부모 페이지명
    pIsMngr: Boolean, // 지점장여부
    fcDtlData: '',
  },
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      //영업 관리자
      lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
      lv_HofOrg: {key: '00000000', label: '전사'}, // 선택사업부
      lv_DofOrg: {key: '0', label: '전체'}, // 선택지역단
      lv_FofOrg: {key: '0', label: '전체'}, // 선택지점
      lv_FofCnslt: {key: '0', label: '전체'}, // 선택컨설턴트

      lv_HofOrgData: [], // 사업부 목록
      lv_DofOrgData: [], // 지역단 목록
      lv_FofOrgData: [], // 지점 목록
      lv_FofCnsltData: [], // 컨설턴트 목록
      lv_OrgAuth: {}, // 지점권한

      // 공통 변수
      userInfo : {},

      // 조회 변수
      searchType : '고객명 검색',
      searchText : '',
      searchCount : 0, // 검색 결과 숫자 표시
      searchResultText : '',

      isLoading : false,
      searchTelHistoryList : [], // 고객명 > 채널고객ID로 조회한 검색결과 목록
      originSearchTelHistoryList : [], // 고객명 > 채널고객ID로 조회한 검색결과 목록

      selectedCustChnlCustId : '', // 고객명 검색결과 고객List에서 컨설턴트가 선택한 채널고객ID
      isCustomerSelected : false, //고객선택 팝업에서 고객 선택 여부

      isSearchCustomerActionDone : false, //채널고객ID 고객검색 API 호출여부
      isSearchMemoActionDone : false, //메모 고객검색 API 호출여부

      cnsltNo : '',

      lv_IsMSPAP312P : false,
      popUp312 : {},

      /* 계약 정렬 선택값 */
      sortCdList : apConstants.CALL_ACTIVITY_SORT_CD_LIST,
      sortCd : {}, // 선택된 정렬 데이터// 조회 FC
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    fn_SearchKeyword() {
      return this.fn_getSearchKeyword(this.searchText)
    },

    fn_IsManager () {
      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        return true
      } else {
        return false
      }
    },

    fn_IsEmptySearchResult () {
      if (this.isSearchCustomerActionDone == true || this.isSearchMemoActionDone == true) {
        if (this.searchType == '고객명 검색') {
          //관리자
          if (this.fn_IsManager == true) { 
            if (this.searchTelHistoryList.length <= 0) {
              return true
            } else {
              return false
            }
          } else {
            //일반 컨설턴트
            if ( this.isCustomerSelected && this.isSearchCustomerActionDone ) {
              if (this.searchTelHistoryList.length <= 0) {
                return true
              } else {
                return false
              }
            }
            return false
          }
        } else if (this.searchType == '메모 검색') {
          if (this.isSearchMemoActionDone) {
            if (this.searchTelHistoryList.length <= 0) {
              return true
            } else {
              return false
            }
          }
        }

      } else {
        return false
      }
    },
  },
  
  /** watch 정의 영역 */
  watch: {
    searchText: {
      immediate: true,
      handler(val) {
        if (this.DEBUG) console.log('searchText length', val.length)

        if (this.fn_SearchKeyword.length >= 1) {
          // empty          
        } else {
          this.fn_Onload() // 화면초기화
          this.$nextTick(_ => {
            // 검색입력영역 focus
            this.$refs.searchTextInput.focus()
          })
        }
      }
    },

    'sortCd.value'(value) {
      let selSortCd = null
      selSortCd = this.sortCdList.filter(item => {
        return item.value === value
      })
      this.fn_GetSortListBySortCd(selSortCd[0])
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('FULL')
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    
    let tmp = this.$route.params

    if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y' && Object.keys(tmp).length !== 0) { // MSPBC003M에서 타 사번 조회된 상태로넘어온 경우
      if (!this.$bizUtil.isEmpty(tmp.cnsltNo)) {
        this.cnsltNo = tmp.cnsltNo
      } else if (tmp.pData?.key){
        this.cnsltNo = tmp.pData.key
        this.fcData = tmp.pData
      } else {
        this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
      }
    } else {
      this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
    }

    this.fn_Onload()

    if(!this.$bizUtil.isEmpty(this.$props.fcDtlData) && !this.$bizUtil.isEmpty(this.$props.fcDtlData.key) && Object.keys(this.$props.fcDtlData).length !== 0) {
      this.lv_FofCnslt.key = this.$props.fcDtlData.key
      this.lv_FofCnslt.label = this.$props.fcDtlData.label
    } else {
      this.lv_FofCnslt.key = this.getStore('userInfo').getters.getUserInfo.userId
      this.lv_FofCnslt.label = this.getStore('userInfo').getters.getUserInfo.userNm
    }

    let fcDtlData = this.fcDtlData

    if (!this.$bizUtil.isEmpty(fcDtlData) && !this.$bizUtil.isEmpty(fcDtlData.hofOrgNo) && Object.keys(fcDtlData).length !== 0) {
      this.lv_HofOrg = fcDtlData.hofOrg
      this.lv_DofOrg = fcDtlData.dofOrg
      this.lv_FofOrg = fcDtlData.fofOrg
    } else {
      this.lv_HofOrg.key = this.getStore('userInfo').getters.getUserInfo.hofOrgNo
      this.lv_DofOrg.key = this.getStore('userInfo').getters.getUserInfo.dofOrgNo
      this.lv_FofOrg.key = this.getStore('userInfo').getters.getUserInfo.fofOrg
    }
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPAP311P')

    switch (this.pPage) {
      // 전체고객
      case 'MSPAP310M':
        this.searchCustNm = this.pSearchCustNm
        this.searchMemo = this.pSearchMemo
        this.fn_GetFofRol()
        break
    }
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_Onload () {
      this.searchText = ''
      this.searchResultText = ''
      this.searchTelHistoryList = []
      this.originSearchTelHistoryList = []
      this.searchCount = 0

      this.isSearchMemoActionDone = false
      this.isSearchCustomerActionDone = false

      this.isLoading = false
      this.isCustomerSelected = false
      this.selectedCustChnlCustId = ''

      this.sortCd = this.$bizUtil.cloneDeep(this.sortCdList[0])
    },

    /******************************************************************************
     * Function명 : fn_GetFilteredList
     * 설명       : 고객 전화활동 이력 중 수신/발신 내역 필터링
    ******************************************************************************/
    fn_GetFilteredList (origin) {
      var filtered = []
      origin.forEach(item => {
        if (item.mobslCustCntcId === 'HC08' 
            || item.mobslCustCntcId === 'HC10' 
            || item.mobslCustCntcId === 'HC11' 
            || item.mobslCustCntcId === 'SEND' 
            || item.mobslCustCntcId === 'RECI') 
        {
          let cntcId = ''
          if (item.mobslCustCntcId === 'HC08' || item.mobslCustCntcId === 'HC10' || item.mobslCustCntcId === 'SEND') cntcId = '발신'
          if (item.mobslCustCntcId === 'HC11' || item.mobslCustCntcId === 'RECI') cntcId = '수신'

          let phclMemoCntnt = ''
          if (!this.$bizUtil.isEmpty(item.phclMemoCntnt)) {
            phclMemoCntnt = item.phclMemoCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')
            //phclMemoCntnt = item.phclMemoCntnt.replace(/(?:\r\n|\r|\n)/g, '\n')
          }
          
          let tmpObj = {
            'name'             : item.custNm,
            'CntcCntnt'        : item.mobslCntcCntnt,
            'memo'             : phclMemoCntnt,
            'memoOrigin'       : item.phclMemoCntnt,
            'SR'               : cntcId,
            'CheckSn'          : item.mobslCntcCheckSn,
            'chnlCustId'       : item.mobslChnlCustId,
            'mobslCntcOccuYmd' : item.mobslCntcOccuYmd,
            'mobslCntcTm'      : item.mobslCntcTm,
          }

          filtered.push(tmpObj)
        }
      });
      return filtered
    },

    /******************************************************************************
     * Function명 : fn_OnClickSearch
     * 설명       : 조회 버튼 클릭 시 현재 탭에 따라 고객명/메모 조건부 검색
    ******************************************************************************/
    fn_OnClickSearch () {
      console.log('fn_OnClickSearch', this.searchType)
      console.log('fn_OnClickSearch this.fn_IsManager', this.fn_IsManager)

      if (this.searchType == '고객명 검색') {
        console.log('고객명 검색 클릭')
        this.fn_OpenMSPAP312P()
      } else if (this.searchType == '메모 검색') {
        console.log('메모 검색 클릭')
        this.fn_Search()
      }
    },

    /*********************************************************
     * Function명: fn_OpenMSPAP312P
     * 설명: 고객등록 / 선택 팝업 Open/Close
     * Params: pPage: 호출하는 화면ID
     *         pIsBackKeyListen: 
     *         pFcSchdlSno: 일정추가 / 삭제 판단여부
     *         pMobslChnlCustId: 채널고객ID
     *         pCustAlctnCmpgnId: 캠페인ID
     *         pChoiceYmd: 선택된 날짜
     *         pSrnId: 화면ID
     * Return: N/A
     *********************************************************/
    fn_OpenMSPAP312P () {
      if (this.fn_IsManager == false) {
        if (this.searchText.trim() === '') {
          this.fn_BottomAlert('고객명을 입력하세요.')
          return
        }
      }

      this.isCustomerSelected = false

      var cnsltNo = this.getStore('userInfo').getters.getUserInfo.userEno
      if (this.fn_IsManager) {
        cnsltNo = this.lv_FofCnslt.key
      }

      let lv_Vm = this
      lv_Vm.popUp312 = lv_Vm.$bottomModal.open(MSPAP312P, {
        properties: {
          pCnsltNo: cnsltNo,
          pCustNm: this.searchText
        },
        listeners: {
          confirmPopup: (pData) =>{
            lv_Vm.$bottomModal.close(lv_Vm.popUp312)
            this.lv_SearchData = {}
            Object.assign(this.lv_SearchData, pData)

            lv_Vm.fn_ConfirmMSPAP312P(this.lv_SearchData)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_ConfirmMSPAP312P
     * 설명       : 고객검색 팝업창에서 확인 버튼 클릭 시 호출.
                  - 팝업창 닫고, 팝업에서 넘어온 채널 고객 ID 설정
    ******************************************************************************/
    fn_ConfirmMSPAP312P (param) {
      console.log('fn_ConfirmMSPAP312P', param)
      if (param === null) {
        this.fn_BottomAlert('고객명을 입력하세요.')
        return
      }

      this.isCustomerSelected = true

      this.selectedCustChnlCustId = param.chnlCustId
      this.searchText = param.custNm
      this.searchResultText = param.custNm

      this.fn_Search()
    },

    /******************************************************************************
     * Function명 : revealSearchWord
     * 설명       : memo text에 검색단어를 파란색으로 굵게 표시한다
    ******************************************************************************/
    fn_RevealSearchWord () {

      console.log('revealSearchWord')

      // memo text에 검색단어를 파란색으로 굵게 표시한다
      if (this.searchText.length === 0 || this.searchText.trim() === '') return

      this.searchCount = 0 // 검색 결과 숫자 표시

      if (this.searchTelHistoryList?.length) {
        for (let i = 0; i < this.searchTelHistoryList.length; i++ ) {
          for (let k = 0; k < this.searchTelHistoryList[i].data.length; k++ ){
            if (this.searchTelHistoryList[i].data[k].memo){
              let resetResult = this.searchTelHistoryList[i].data[k].memo.replaceAll('<i>', '') // 기존 검색 결과 표시 <i>는 삭제
              this.searchTelHistoryList[i].data[k].memo = resetResult
              resetResult = this.searchTelHistoryList[i].data[k].memo.replaceAll('</i>', '')
              this.searchTelHistoryList[i].data[k].memo = resetResult
              if (this.searchTelHistoryList[i].data[k].memo.includes(this.searchText)) this.searchCount++
              // <i></i> tag로 감싸서 표시해 준다 (<i> tag - css는 별도 존재)
              let searchResult = this.searchTelHistoryList[i].data[k].memo.replaceAll(this.searchText, '<i>' + this.searchText + '</i>')
              this.searchTelHistoryList[i].data[k].memo = searchResult
            }
          }
        }
      }
    },

    fn_getSearchKeyword(text) {
      return text?.replace(/\s|-/g, '').toUpperCase() || ''
    },

    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      console.log('fn_Clear 진입!')
      switch (this.pPage) {
        // 전체고객
        case 'MSPAP310M':
          this.fn_Onload()
          break
      }
    },

    /*********************************************************
     * Function명: fn_SaveMemo
     * 설명: 메모저장/수정
     *********************************************************/
    fn_SaveMemo(item) {
      console.log('fn_SaveMemo 진입!', item)

      if (this.fn_IsManager == true) {
        this.fn_BottomAlert('수정 및 삭제가 불가능합니다.')
        return
      }

      let popup139 = this.$bottomModal.open(MSPCM139P, {
        properties: {
          pMobslCntcCheckSn: item.CheckSn,
          pChnlCustId      : item.chnlCustId,
          custNm           : item.custNm,
          showTime         : item.CntcCntnt,
          memoCntnt        : item.memoOrigin
        },

        listeners: {
          onPopupClose: () => {
            // 모달 닫기
            console.log('MSPCM290D onPopupClose')
            this.$bottomModal.close(popup139)
          },

          onPopupConfirm: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(popup139)
            this.fn_Search()
          },
        }
      },
      {
        properties: {
          preventTouchClose: true,
        }
      })
    },

    /*****************************************************************************
     * Function명 : fn_DeleteMemo
     * 설명       : 전화메모 단건 삭제
     ******************************************************************************/
    fn_DeleteMemo(CheckSn, chnlCustId) {
      console.log('fn_DeleteMemo 진입!', CheckSn, chnlCustId)
      const lv_Vm   = this 
      const trnstId = 'txTSSCM58D3' 
      const auth    = 'S'

      let pParams = {
        mobslCntcCheckSn: CheckSn,
        mobslChnlCustId : chnlCustId,
        mobslCnsltEno   : this.userInfo.userId,
      }
      console.log('delete param>>!', pParams)

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response?.body && response.body.rsltYn === '1') {
            console.log('삭제완료')
            lv_Vm.fn_Search()
          } else {
            let msg = response.msgComm.msgDesc
            lv_Vm.$cmUtil.fn_OpenAlert(true, msg)
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_BottomConfirm
     * 설명       : Confirm 팝업 호출(확인, 취소)
     ******************************************************************************/
    fn_BottomConfirm (contents, CheckSn, chnlCustId) {
      console.log('fn_BottomConfirm 진입!', contents, CheckSn, chnlCustId)

      if (this.fn_IsManager == true) {
        this.fn_BottomAlert('수정 및 삭제가 불가능합니다.')
        return
      }

      this.lv_ConfirmPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title        : '알림',
          content      : contents,
          title_pos_btn: "삭제",
          title_neg_btn: "취소"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_ConfirmPop);
            this.fn_DeleteMemo(CheckSn, chnlCustId)
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_ConfirmPop);
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 고객명/메모 탭 Input 값 기준으로 전화활동 검색
     ******************************************************************************/
    fn_Search () {
      console.log('fn_Search 진입 searchType', this.searchType)
      console.log('fn_Search 진입 fn_IsManager', this.fn_IsManager)

      if (this.searchType === '고객명 검색') {
        this.fn_SearchByCustNm()
      } else if (this.searchType === '메모 검색') {
        this.fn_SearchByMemo()
      }
    },
    /******************************************************************************
     * Function명 : fn_SearchByCustNm
     * 설명       : 고객명 탭 선택 후 조회 시 실행 : 고객명 Input 값 기준으로 전화활동 검색
     ******************************************************************************/
    fn_SearchByCustNm() {
      console.log('fn_SearchByCustNm 진입', this.selectedCustChnlCustId)

      //영업관리자가 접속하는 경우 검색어를 입력하지 않아도 검색이 되어야 함
      if (this.fn_IsManager == false) {
        if (this.searchText.trim() === '') {
          this.fn_BottomAlert('고객명을 입력하세요.')
          return
        }

        if (this.isCustomerSelected == false) {
          this.fn_BottomAlert('고객선택 팝업에서 고객을 선택하세요.')
          return
        }
      }

      const lv_Vm   = this
      const trnstId = 'txTSSAP06S2' //전화문자연결이력 조회 (일자조건 무시)
      const auth    = 'S'

      var cnsltNo = this.getStore('userInfo').getters.getUserInfo.userEno
      if (this.fn_IsManager) {
        cnsltNo = this.lv_FofCnslt.key
      }

      let pParams = {
        eno: cnsltNo, // 모바일영업컨설턴트사번

        flagMobslChnlCustId: 1, //모바일영업채널고객ID 적용여부 플래그
        chnlCustId: this.selectedCustChnlCustId, //모바일영업채널고객ID

        flagMobslCntcOccuYmd: 0, //모바일영업접촉발생일자 적용여부 플래그
        mobslCntcOccuYmd: '', //모바일영업접촉발생일자

        flagPhclMemoCntnt: 0, //통화메모내용 적용여부 플래그
        phclMemoCntnt: '', //통화메모내용

        flagMobslCustCntcId: 0, //발신/수신/전체 적용여부 플래그
      }

      //영업관리자가 접속하는 경우 검색어를 입력하지 않아도 검색이 되어야 함
      if (this.fn_IsManager == true) {
        if (this.isCustomerSelected == true) {
          pParams.flagMobslChnlCustId = 1 //모바일영업채널고객ID 적용여부 플래그
        } else {
          pParams.flagMobslChnlCustId = 0 //모바일영업채널고객ID 적용여부 플래그
        }
      }

      console.log('select param>>!', pParams)

      this.isLoading = true
      this.isSearchCustomerActionDone = false

      this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null) {
              if (response.body !== null && response.body.telHstrVOMap !== null) {
                let telHistoryObj = response.body.telHstrVOMap

                lv_Vm.searchCount = 0
                lv_Vm.searchTelHistoryList = [] //초기화
                lv_Vm.originSearchTelHistoryList = [] //초기화

                for (const key in telHistoryObj) {
                  let telHistoryListByDate = telHistoryObj[key]
                  const filteredListByDate = lv_Vm.fn_GetFilteredList(telHistoryListByDate)
                  if (filteredListByDate.length > 0) {
                    if (key.length == 8) { //key : yyyymmdd value
                      var telHistoryObjByDate = {}
                      var yyyymmdd = lv_Vm.fn_GetFormattedDateString(key)

                      telHistoryObjByDate['date'] = key
                      telHistoryObjByDate['formattedDate'] = yyyymmdd
                      telHistoryObjByDate['data'] = filteredListByDate
                      lv_Vm.searchTelHistoryList.push(telHistoryObjByDate)
                      lv_Vm.originSearchTelHistoryList.push(telHistoryObjByDate)

                      lv_Vm.searchCount += filteredListByDate.length
                    }
                  }
                }

                console.log('lv_Vm.sortCd', lv_Vm.sortCd)
                console.log('lv_Vm.searchTelHistoryList', lv_Vm.searchTelHistoryList)

                lv_Vm.fn_GetSortListBySortCd(lv_Vm.sortCd)
              }
            }
            lv_Vm.isLoading = false
            lv_Vm.isSearchCustomerActionDone = true
          })
          .catch(function (error) {
            window.vue.error(error)
          })
    },
    /******************************************************************************
     * Function명 : fn_SearchByMemo
     * 설명       : 메모 탭 선택 후 조회 시 실행 : 메모 Input 값 기준으로 전화활동 검색
     ******************************************************************************/
    fn_SearchByMemo() {
      console.log('fn_SearchByMemo 진입', this.searchText)

      //영업관리자가 접속하는 경우 검색어를 입력하지 않아도 검색이 되어야 함
      if (this.fn_IsManager == false) {
        if (this.searchText.trim() === '') {
          this.fn_BottomAlert('검색어를 입력하세요.')
          return
        }
      }

      const lv_Vm   = this
      const trnstId = 'txTSSAP06S2' //전화문자연결이력 조회 (일자조건 무시)
      const auth    = 'S'

      var cnsltNo = this.getStore('userInfo').getters.getUserInfo.userEno
      if (this.fn_IsManager) {
        cnsltNo = this.lv_FofCnslt.key
      }

      let pParams = {
        eno: cnsltNo, // 모바일영업컨설턴트사번

        flagMobslChnlCustId: 0, //모바일영업채널고객ID 조건절 적용여부 플래그
        chnlCustId: this.selectedCustChnlCustId, //모바일영업채널고객ID

        flagMobslCntcOccuYmd: 0, //모바일영업접촉발생일자 조건절 적용여부 플래그
        mobslCntcOccuYmd: '', //모바일영업접촉발생일자

        flagPhclMemoCntnt: 1, //통화메모내용 조건절 적용여부 플래그
        phclMemoCntnt: this.searchText, //통화메모내용

        flagMobslCustCntcId: 0, //발신/수신/전체 적용여부 플래그
      }

      //영업관리자가 접속하는 경우 검색어를 입력하지 않아도 검색이 되어야 함
      if (this.fn_IsManager == true) {
        if (this.searchText.trim() === '') {
          pParams.flagPhclMemoCntnt = 0 //통화메모내용 조건절 적용여부 플래그
        } else {
          pParams.flagPhclMemoCntnt = 1 //통화메모내용 조건절 적용여부 플래그
        }
      }

      console.log('select param>>!', pParams)

      this.searchResultText = this.searchText

      this.isLoading = true
      this.isSearchMemoActionDone = false

      this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null) {
              if (response.body !== null && response.body.telHstrVOMap !== null) {
                let telHistoryObj = response.body.telHstrVOMap
                lv_Vm.searchTelHistoryList = [] //초기화
                lv_Vm.originSearchTelHistoryList = [] //초기화

                for (const key in telHistoryObj) {
                  let telHistoryListByDate = telHistoryObj[key]

                  console.log('telHistoryListByDate', telHistoryListByDate)

                  const filteredTelHistoryList = lv_Vm.fn_GetFilteredList(telHistoryListByDate)

                  if (filteredTelHistoryList.length > 0) {
                    if (key.length == 8) { //key : yyyymmdd value
                      var telHistoryObjByDate = {}
                      var yyyymmdd = lv_Vm.fn_GetFormattedDateString(key)

                      telHistoryObjByDate['date'] = key
                      telHistoryObjByDate['formattedDate'] = yyyymmdd
                      telHistoryObjByDate['data'] = filteredTelHistoryList

                      lv_Vm.searchTelHistoryList.push(telHistoryObjByDate)
                      lv_Vm.originSearchTelHistoryList.push(telHistoryObjByDate)
                    }
                  }
                }

                lv_Vm.fn_GetSortListBySortCd(lv_Vm.sortCd)

                console.log('lv_Vm.searchTelHistoryList', lv_Vm.searchTelHistoryList)
                if (lv_Vm.searchTelHistoryList.length > 0) {
                  lv_Vm.fn_RevealSearchWord(lv_Vm)
                }
              }
            }
            lv_Vm.isLoading = false
            lv_Vm.isSearchMemoActionDone = true
          })
          .catch(function (error) {
            window.vue.error(error)
          })
    },

    fn_GetFormattedDateString (yyyymmdd) {
      if (yyyymmdd.length == 8) {
        return yyyymmdd.substring(0, 4) + '.' + yyyymmdd.substring(4, 6) + '.' + yyyymmdd.substring(6)
      } else {
        console.log('fn_GetFormattedDateString error case : yyyymmdd', yyyymmdd)
        return ''
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenCustNmUiCard
     * 설명       : 고객 UI Name Card 호출 - 바텀시트 (전화활동 고객명 터치 시 호출됨)
     ******************************************************************************/
    fn_OpenCustNmUiCard (item) {  
      console.log('fn_OpenCustNmUiCard 진입')
      let lv_Vm = this
      let nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPAP311P', //호출 화면 ID
        cnsltNo : this.userInfo.userId, //FC 사번
        custNm : item.custNm, //고객명
        contNo : '' //계약번호
      }

      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, (modalId) => {
        //Close 콜백 처리
        return
      })
    },

   /******************************************************************************
    * Function명 : fn_BottomAlert
    * 설명       : 알림 팝업 호출
    ******************************************************************************/
   fn_BottomAlert (contents) {
    this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
      properties: {
        single: true,
        title: '알림',
        content: contents
      },
      listeners: {
        onPopupConfirm: () => {
          this.$bottomModal.close(this.lv_AlertPop);
        },
        onPopupClose: () => {
          this.$bottomModal.close(this.lv_AlertPop);
          }
        }
      })
    },

    /**
     * @function fn_GetSortListBySortCd
     * @name     데이터정렬함수
     * @description 전화활동 화면에서 정렬선택 시 해당 함수 호출
     */
    fn_GetSortListBySortCd (sortCd) {
      this.sortCd = this.$bizUtil.cloneDeep(sortCd)

      let type = sortCd.type
      let sort = sortCd.sort

      if (type == 'all') {
        this.searchTelHistoryList = this.$bizUtil.cloneDeep(this.originSearchTelHistoryList)
      } else if (type == 'receive' || type == 'send') {
        this.searchTelHistoryList = this.fn_GetListWithFilterBySR(type)
      }

      if (this.searchTelHistoryList.length == 1) {
        if (sort === 'ASC') {
          // 오름차순
          this.searchTelHistoryList[0]['data'] = this.searchTelHistoryList[0]['data'].sort((a, b) => a['mobslCntcTm'] - b['mobslCntcTm'])
        } else {
          // 내림차순
          this.searchTelHistoryList[0]['data'] = this.searchTelHistoryList[0]['data'].sort((a,b) => b['mobslCntcTm'] - a['mobslCntcTm'])
        }
      } else if (this.searchTelHistoryList.length > 1) {
        if (sort === 'ASC') {
          // 오름차순
          this.searchTelHistoryList = this.searchTelHistoryList.sort((a, b) => a['date'] - b['date'])
        } else {
          // 내림차순
          this.searchTelHistoryList = this.searchTelHistoryList.sort((a,b) => b['date'] - a['date'])
        }
      }

      this.searchCount = 0
      this.searchTelHistoryList.forEach(telHistoryByDate => {
        this.searchCount += telHistoryByDate['data'].length
      })
    },

    /**
     * @function fn_GetListWithFilterBySR
     * @param    {String} type
     * @name     정렬선택 필터값과 일치하는 데이터를 리턴한다.
     * @description 정렬선택 바텀시트에서 수신만보기 혹은 발신만보기 정렬 선택 시 해당 함수 호출
     */
    fn_GetListWithFilterBySR (type) {
      var filtered = []
      var SR = ''

      if (type == 'receive') {
        SR = '수신'
      } else if (type == 'send') {
        SR = '발신'
      }

      this.originSearchTelHistoryList.forEach(objByDate => {
        console.log('receive objByDate', objByDate)
        
        var receiveList = []

        objByDate['data'].forEach(listItem => {
          console.log('receive listItem', listItem)
          if (listItem.SR === SR) {
            receiveList.push(listItem)
          }
        })

        if (receiveList.length > 0) {
          var receiveObjByDate = {}
          receiveObjByDate['date'] = objByDate['date']
          receiveObjByDate['formattedDate'] = objByDate['formattedDate']
          receiveObjByDate['data'] = receiveList
          filtered.push(receiveObjByDate)
        }
      })

      return filtered
    },
    

    //////////////////////////////////////////////////
    // 관리자로 로그인 한 경우 사용되는 공통함수
    //////////////////////////////////////////////////

    /******************************************************************************
     * Function명 : fn_GetDofData
     * 설명       : 지역단 조회 ( SearchOrg 참고 )
     * 인자       :
     * Return     :
    ******************************************************************************/
    fn_GetDofData ( param ) {
      console.log('fn_GetDofData >> ', param)
      if ( param ) {
        this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
        if (this.lv_DofOrgData) { this.lv_DofOrgData.slice(0) }
        if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
        if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
        this.lv_DofOrg = {key: '0', label: '전체'}
        this.lv_FofOrg = {key: '0', label: '전체'}
        this.lv_FofCnslt = {key: '0', label: '전체'}
        if (this.lv_HofOrg.key !== '0' && this.lv_HofOrgData.length > 1 ) {
          this.$bizUtil.selListDofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_GetFofCnsltData
    * 설명       : 컨설턴트 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofCnsltData (param) {
      console.log('fn_GetFofCnsltData >> ', param)
      if ( param ) {
        this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
        this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
        this.lv_SearchParam.fofOrgNo = this.lv_FofOrg.key
        this.lv_SearchParam.orgNo = this.lv_FofOrg.key
        if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
        this.lv_FofCnslt = {key: '0', label: '전체'}
        this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      } else {
        this.lv_SearchParam.hofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo
        this.lv_SearchParam.dofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstDofNo
        this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      }
    },

    /******************************************************************************
    * Function명 : fn_GetFofData
    * 설명       : 지점 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofData (param) {
      console.log('fn_GetFofData >> ', param)
      if ( param ) {
        this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
        this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
        if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
        if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
        this.lv_FofOrg = {key: '0', label: '전체'}
        this.lv_FofCnslt = {key: '0', label: '전체'}
      }
      this.$bizUtil.selListFofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
    },

    /******************************************************************************
    * Function명 : fn_GetFofCnsltData
    * 설명       : 컨설턴트 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofCnsltData (param) {
      console.log('fn_GetFofCnsltData >> ', param)
      if ( param ) {
        this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
        this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
        this.lv_SearchParam.fofOrgNo = this.lv_FofOrg.key
        this.lv_SearchParam.orgNo = this.lv_FofOrg.key
        if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
        this.lv_FofCnslt = {key: '0', label: '전체'}
        this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      } else {
        this.lv_SearchParam.hofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo
        this.lv_SearchParam.dofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstDofNo
        this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      }
    },

    /******************************************************************************
    * Function명 : fn_LoadingData
    * 설명       : 시스템 옵션 조회
    * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
    * Return     : BCOptnMngSrchOSVO
    ******************************************************************************/
    fn_OrgSearchCallBack ( rtnData, sType ) {
      console.log('fn_OrgSearchCallBack >>> ', rtnData, sType)
      if (sType === 'CNSLT') {
        this.lv_FofCnsltData.slice(0)
        this.lv_FofCnsltData = rtnData
        this.lv_IsFirst = false
      } else {
        this.lv_HofOrgData = rtnData[0]
        this.lv_DofOrgData = rtnData[1]
        this.lv_FofOrgData = rtnData[2]
        console.log('this.lv_IsFirst >> ', this.lv_IsFirst)
        console.log(this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo)
        console.log(this.getStore('userInfo').getters.getUserInfo.onpstDofNo)
        console.log(this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo)
        if (this.lv_IsFirst) {
          let isSelHof = false
          for ( let oData of rtnData[0] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo ) {
              this.lv_HofOrg = oData
              isSelHof = true
              break
            }
          }
          if (!isSelHof) this.lv_HofOrg = {key: '00000000', label: '전사'}

          let isSelDof = false
          for ( let oData of rtnData[1] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstDofNo ) {
              this.lv_DofOrg = oData
              isSelDof = true
              break
            }
          }
          if (!isSelDof || !isSelHof) this.lv_DofOrg = {key: '0', label: '전체'}

          let isSelFof = false
          for ( let oData of rtnData[2] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo ) {
              this.lv_FofOrg = oData
              isSelFof = true
              break
            }
          }
          if (!isSelFof || !isSelDof) this.lv_FofOrg = {key: '0', label: '전체'}
          if(this.$props.pPage === 'MSPAP610M' && this.$props.pFlag !== '0'){
            this.fn_FcThmmActActul()
          }else{
            this.fn_GetFofCnsltData(false)
          }
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_SetHofData
    * 설명       : 시스템 옵션 조회
    * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
    * {isEntpwRolYn[전사역할여부]=Y/N,
    * isHofRolYn[사업부역할여부]=Y/N,
    * isDofRolYn[지역단역할여부]=Y/N,
    * isEofRolYn[지점역할여부]=Y/N}
    * Return     : BCOptnMngSrchOSVO
    ******************************************************************************/
    fn_GetFofRolCallBack ( pData ) {
      console.log('fn_GetFofRolCallBack >> ', pData)
      if ( pData ) {
        this.lv_OrgAuth = pData
        let sOrgAuth = pData.isEntpwRolYn + pData.isHofRolYn + pData.isDofRolYn + pData.isEofRolYn
        console.log( '-------------------' )
        console.log( sOrgAuth )
        console.log( 'HOF::' + this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo )
        console.log( 'DOF::' + this.getStore('userInfo').getters.getUserInfo.onpstDofNo )
        console.log( 'FOF::' + this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo )
        console.log( this.getStore('userInfo').getters.getUserInfo )
        console.log( '-------------------' )
        // lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
        this.lv_SearchParam.fofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
        this.lv_SearchParam.orgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
        this.$bizUtil.selUserOrgList(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      }
    },
    /******************************************************************************
    * Function명 : fn_GetFofRol
    * 설명       : 사용자의 조직조회 권한 확인
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofRol () {
      console.log('fn_GetFofRol >> ')
      this.lv_IsFirst = true
      this.$bizUtil.selFofRol({}, this.fn_GetFofRolCallBack )
    },

    fn_FcThmmActActul() {
      // 세무팀장활동확인
      let a = this.$props.pPage
      if(this.$props.pPage === 'MSPAP610M'){
        let item = {
          hofOrgNo: this.lv_HofOrg.key,
          dofOrgNo: this.lv_DofOrg.key === '0' ? '1' : this.lv_DofOrg.key,
          fofOrgNo: this.lv_FofOrg.key === '0' ? '1' : this.lv_FofOrg.key
        }
        if(this.lv_IsFirst && this.$props.pFlag !== '0'){
          this.p_vm.fn_srch(item)
        } else{
          this.$emit('onPopupSearch', item)
        }
      }
      // 조직명장수수료 소득관리
      else if(this.$props.pPage === 'MSPAP070M') {
        let item = this.$refs.refFofCnsltData.getSelectedItem()
        let data = {
          hofOrg: this.lv_HofOrg,
          dofOrg: this.lv_DofOrg,
          fofOrg: this.$refs.refFofOrgData.getSelectedItem()
        }
        console.log('지점 >> ', data.fofOrg)
        if(item === undefined){
          this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해주세요.')
          return
        } else {
          if ( item.key === '' || item.key === '0') {
            this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해주세요.')
            return
          }
        }

        if(!this.$bizUtil.isEmpty(item.label)){
          let sp = item.label.split(' ')
          if(sp.length > 1){
            item.label = sp[0]
          }
        }
        this.$emit('onPopupSearch', item, data)
      }
      else if(a === 'MSPBC003M') {
        let data = {
          hofOrg: this.lv_HofOrg,
          dofOrg: this.lv_DofOrg,
          fofOrg: this.$refs.refFofOrgData.getSelectedItem()
        }
        let item = this.$refs.refFofCnsltData.getSelectedItem()

        if ( item == null || item.key === '' || item.key === '0') {
          this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해주세요.')
          return
        }

        if(!this.$bizUtil.isEmpty(item.label)){
          let sp = item.label.split(' ')
          if(sp.length > 1){
            item.label = sp[0]
          }
        }

        this.$emit('onPopupSearch', item, data)
      }
      // 승강격실적조회
      else{
        let item = this.$refs.refFofCnsltData.getSelectedItem()
        
        if ( item == null || item.key === '' || item.key === '0') {
          this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해주세요.')
          return
        }

        if(!this.$bizUtil.isEmpty(item.label)){
          let sp = item.label.split(' ')
          if(sp.length > 1){
            item.label = sp[0]
          }
        }
        this.$emit('onPopupSearch', item)
      }
    },

    //////////////////////////////////////////////////
    // 관리자로 로그인 한 경우 사용되는 공통함수 END
    //////////////////////////////////////////////////

  }
}
</script>
